@import "@/scss/_variables.scss";































































































































.export-profile-teaser {
  position:relative;
  background-color: #605E5B;
  padding:15px;
  p {
    z-index: 0;
    position:relative;
  }
}
.profile-edit-button {
  z-index:1;
  position:absolute;
  top:15px;
  right: 15px;
  display:inline-block;
}
